import React from 'react';
import PropTypes from 'prop-types';

import { Route, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { useAppContext } from '../context/appContext';
import Loading from '../common/loading.js';
import Partner from '../models/partner';
import PageNotFound from './pageNotFound';
import Template from './template/template';
import Invitations from './invitations/invitations';
import OutdatedBrowser from '../common/outdated-browser';

// credits to antoni (https://stackoverflow.com/a/62640342)
const colorShade = (col, amt) => {
    col = col.replace(/^#/, '');

    if (col.length === 3) {
        let [r, g, b] = col.split('');
        col = `${r}${r}${g}${g}${b}${b}`;
    }

    let [r, g, b] = col.match(/.{2}/g);
    [r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

    r = Math.max(Math.min(255, r), 0);
    g = Math.max(Math.min(255, g), 0);
    b = Math.max(Math.min(255, b), 0);

    return `${r}, ${g}, ${b}`;
};

function edit_css(color) {
    if (!color) {
        return null;
    }
    const darkenColor = colorShade(color, -40);
    const lightenColor = colorShade(color, 40);
    color = colorShade(color, 0);
    let root = document.documentElement;
    root.style.setProperty('--primary-color', color);
    root.style.setProperty('--primary-darker-color', darkenColor);
    root.style.setProperty('--primary-lighter-color', lightenColor);
}

const createFavicon = (logo) => {
    if (!logo) {
        return;
    }
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = logo;
    document.getElementsByTagName('head')[0].appendChild(link);
};

export default function PartnerRouter({ partnerId, history, match }) {
    const [context, setContext] = useAppContext();
    const { path } = match;

    const style_customization = React.useCallback((partner) => {
        if (!partner) {
            return;
        }
        createFavicon(partner.resolved_logo);
        edit_css(partner.resolved_color);
    }, []);

    React.useEffect(() => {
        // Partner is already fetched
        if (context.partner && context.partner.uuid === partnerId) {
            return;
        }
        Partner.get({ id: partnerId })
            .then((partner) => {
                style_customization(partner);
                setContext({ partner });
                Sentry.configureScope((scope) => {
                    scope.setExtra('partner_id', partnerId);
                });
            })
            .catch((e) => {
                return history.push('/forbidden');
            });
    }, [partnerId, context, history, setContext, style_customization]);

    if (!context.partner) {
        return <Loading />;
    }

    return (
        <OutdatedBrowser>
            <Template partner={context.partner}>
                <Switch>
                    <Route
                        exact
                        path={`${path}/`}
                        render={({ match, history }) => {
                            let url = match.url;
                            let redirect = `${url}/invitations`;
                            if (url[url.length - 1] === '/') {
                                redirect = `${url}invitations`;
                            }

                            return <Redirect to={redirect} />;
                        }}
                    />
                    <Route exact path={`${path}/invitations`} render={() => <Invitations partner={context.partner} />} />
                    <Route exact path={`${path}/forbidden`} render={({ match, history }) => <PageNotFound />} />
                </Switch>
            </Template>
        </OutdatedBrowser>
    );
}

PartnerRouter.propTypes = {
    partnerId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};
