import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import { AppContextProvider } from './context/appContext';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './i18n/i18n';
import 'semantic-ui-css/semantic.min.css';
import '@weezevent/nacre/lib/assets/styles/core.scss';
import 'handsontable/dist/handsontable.full.css';
import './main.css';
import './styles/custom.css';
import { ToastProvider } from '@weezevent/nacre';
import { fetcher } from '@weezevent/core';
import LoadingApp from './components/loadingApp';

// Devs should not add sentry to localconfig to prevent useless errors
if (window.weezConfig.sentry) {
    Sentry.init({
        dsn: window.weezConfig.sentry.dsn,
        environment: window.location.host,
        release: process.env.REACT_APP_RELEASE || 'NO_RELEASE'
    });
}

fetcher.initialize(window.weezConfig.api.endpoint);
export default function Hogwarts() {
    return (
        <React.StrictMode>
            <AppContextProvider>
                <ToastProvider>
                    <Router>
                        <Route
                            path="/"
                            render={() => (
                                <React.Suspense fallback={<LoadingApp />}>
                                    <App />
                                </React.Suspense>
                            )}
                        />
                    </Router>
                </ToastProvider>
            </AppContextProvider>
        </React.StrictMode>
    );
}

ReactDOM.render(<Hogwarts />, document.getElementById('root'));
