import { BaseAPIModel } from '@weezevent/core';

export default class Inventory extends BaseAPIModel {
    fields() {
        return {
            uuid: { readOnly: true, primaryKey: true },
            name: {},
            display_order: {}
        };
    }

    static listUrl({ partnerId }) {
        if (!partnerId) {
            throw new Error(`[Model][Inventory][getAll] partnerId is mandatory`);
        }

        return `/ticket/partners/${partnerId}/inventories`;
    }
}
