import { BaseAPIModel } from '@weezevent/core';
/**
 * Chart.
 *
 * @param {Object} params - Object parameter
 * @constructor
 */
export default class MailStatus extends BaseAPIModel {
    fields() {
        return {
            id: { readOnly: true, primaryKey: true }
        };
    }

    static listUrl({ partnerId, attendeeId }) {
        return `/ticket/partners/${partnerId}/guests/${attendeeId}/mails`;
    }
}
