import { BaseAPIModel } from '@weezevent/core';
/**
 * Chart.
 *
 * @param {Object} params - Object parameter
 * @constructor
 */
export default class PartnerBatchBooking extends BaseAPIModel {
    fields() {
        return {
            booking_uuid: { readOnly: true, primaryKey: true }
        };
    }

    static listUrl({ partnerId }) {
        if (!partnerId) {
            throw new Error('[Model][Partner] partnerId is mandatory');
        }
        return `/ticket/partners/${partnerId}/bookings`;
    }
}
