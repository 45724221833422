import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import { browserVersion, browserName, isIE } from 'react-device-detect';
import { ToastContext } from '@weezevent/nacre';

const minimalCompatibility = {
    Firefox: 57,
    Chrome: 62,
    Opera: 49,
    Edge: 18,
    Safari: 10,
    IE: 11
};

function DefaultMessage() {
    return (
        <Message info>
            <Message.Header>
                <Trans i18nKey="partners.compatibility.title">Obsolete browser</Trans>
            </Message.Header>
            {isIE ? (
                <p>
                    <Trans i18nKey="partners.compatibility.content_ie">
                        Internet Explorer is not supported by this application. Please use a more recent browser (Chrome, Firefox, etc ...)
                    </Trans>
                </p>
            ) : (
                <p>
                    <Trans i18nKey="partners.compatibility.content" browserName={browserName}>
                        Your {browserName} web browser is not up to date. Update your browser for more security and speed and a better experience on this website.
                    </Trans>
                </p>
            )}
            <a className={'wz-button link-button-update-browser'} target={'_blank'} rel="noopener noreferrer" href={'http://www9.browser-update.org/en/update-browser.html'}>
                <Trans i18nKey="partners.compatibility.button_update">Update now</Trans>
            </a>
        </Message>
    );
}

export default function OutdatedBrowser({ children, Content = DefaultMessage, compatibility = minimalCompatibility }) {
    const toast = React.useContext(ToastContext);
    let browserSupported = true;

    if (compatibility[browserName] && browserVersion <= compatibility[browserName]) {
        browserSupported = false;
    }

    React.useEffect(() => {
        if (!browserSupported) {
            toast.info(<Content />, { autoDismiss: false });
        }
    }, []);// eslint-disable-line

    return browserSupported ? children : null;
}

OutdatedBrowser.propTypes = {
    compatibility: PropTypes.object,
    Content: PropTypes.node,
    children: PropTypes.node
};
