import { BaseAPIModel, ArgumentError } from '@weezevent/core';
import i18n from 'i18next';
import { capitalizeFirstLetter } from '../common/utils';

/**
 * Chart.
 *
 * @param {Object} params - Object parameter
 * @constructor
 */
export default class Attendee extends BaseAPIModel {
    fields() {
        return {
            id: { readOnly: true, primaryKey: true }
        };
    }

    static listUrl({ partnerId }) {
        return `/ticket/partners/${partnerId}/guests`;
    }

    toAPI() {
        let ignored = ['first_name', 'last_name'];
        let json = super.toAPI();
        for (let ignore of ignored) {
            if (json[ignore] === null) {
                delete json[ignore];
            }
        }

        return json;
    }

    static bulk_delete({ partnerId, data } = {}) {
        if (!partnerId) {
            throw new ArgumentError('[Model][Attendee] partnerId is mandatory');
        }
        let url = `/ticket/partners/${partnerId}/guests`;
        return BaseAPIModel.fetcher.delete(url, { data });
    }

    get resolved_attendee_name() {
        if (this.object.last_name) {
            return `${this.object.last_name.toUpperCase()} ${capitalizeFirstLetter(this.object.first_name)}`;
        }
        return i18n.t('partners.common.nonNominative').toUpperCase();
    }
}
