import React from 'react';
import PropTypes from 'prop-types';

const AppContext = React.createContext(null);

export function useAppContext() {
    const ctx = React.useContext(AppContext);
    if (!ctx) {
        throw new Error('[useAppContext] Did you call useAppContext outside an AppContextProvider component?');
    }

    const { context, setContext, removeFromContext } = ctx;

    return [context, setContext, removeFromContext];
}

export function AppContextProvider({ children }) {
    const [context, _setContext] = React.useState({});

    const setContext = React.useCallback((ctx = {}) => {
        _setContext((context) => {
            return { ...context, ...ctx };
        });
    }, []);

    const removeFromContext = React.useCallback((name) => {
        _setContext((context) => {
            let newContext = { ...context };
            delete newContext[name];
            return newContext;
        });
    }, []);

    return (
        <AppContext.Provider
            value={{
                context,
                setContext,
                removeFromContext
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

AppContextProvider.propTypes = {
    children: PropTypes.node
};

export default AppContext;
