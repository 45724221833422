import React from 'react';
import { Loader } from 'semantic-ui-react';

export default function LoadingApp() {
    return (
        <div style={{ height: '50vh' }}>
            <Loader active></Loader>
        </div>
    );
}
