import { BaseAPIModel } from '@weezevent/core';
import QueryString from 'querystring';

export default class PartnerUser extends BaseAPIModel {
    fields() {
        return {
            uuid: { primaryKey: true, readOnly: true },
            name: {},
            id: { primaryKey: true, readOnly: true }
        };
    }

    /**
     * Gets the url for this object(s)
     */
    static getItemUrl(...args) {
        let params = args[0];
        if (!params.me && !params.id) {
            throw new Error(`[Model][${this.constructor.name}] id is mandatory`);
        }

        let query = args[1] || {};
        let queryString = QueryString.stringify(query);
        queryString = queryString ? `?${queryString}` : '';
        if (params.me) {
            return `${this.getListUrl(args[0])}${queryString}`;
        }
        return `${this.getListUrl(args[0])}/${params.id}${queryString}`;
    }

    static listUrl({ partnerId, me = false }) {
        if (me) {
            return `/ticket/partners/${partnerId}/me`;
        }
        return `/ticket/partners/${partnerId}/users`;
    }
}
