import { BaseAPIModel } from '@weezevent/core';

export default class RateModel extends BaseAPIModel {
    fields() {
        return {
            id: { readOnly: true, primaryKey: true }
        };
    }

    static listUrl({ partnerId }) {
        if (!partnerId) {
            throw new Error(`[Model][RateModel][getAll] partnerId mandatory`);
        }

        return `/ticket/partners/${partnerId}/rates`;
    }
}
