import React from 'react';
import PropTypes from 'prop-types';
import { WZTemplate, Frame, FrameConsumer } from '@weezevent/nacre';
import Account from './account';
import TicketLogo from '../../images/weezticket.png';
import { useHistory } from 'react-router-dom';
import Styles from '../../styles/components/template.module.css';
import * as ReactRouterDom from 'react-router-dom';

export default function Template({ children, partner }) {
    const history = useHistory();

    return (
        <div className={Styles['logo']}>
            <WZTemplate.WZTemplateProvider
                logo={partner?.resolved_logo || (!partner?.resolved_color || partner?.resolved_color === '#0032fa' ? TicketLogo : null)}
                router={ReactRouterDom}
            >
                <Frame.Provider>
                    <FrameConsumer size="medium" account={<Account partner={partner} history={history} />}>
                        {children}
                    </FrameConsumer>
                </Frame.Provider>
            </WZTemplate.WZTemplateProvider>
        </div>
    );
}

Template.propTypes = {
    partner: PropTypes.object.isRequired,
    children: PropTypes.node
};
