import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use({
        // custom loading backend to avoid cache problems by exploiting create-react-app bundle hashing.
        // uses webpack dynamic imports https://webpack.js.org/api/module-methods/#import-1
        type: 'backend',
        read(language, namespace, callback) {
            import(
                /* webpackChunkName: "translations" */
                /* webpackMode: "lazy" */
                `../locales/${language}/${namespace}.json`
            ).then(
                (res) => {
                    callback(null, res.default);
                },
                (err) => {
                    callback(err, null);
                }
            );
        }
    })
    .use(initReactI18next)
    .init({
        load: 'all',
        fallbackLng: 'en',
        ns: 'index',
        defaultNS: 'index',
        interpolation: {
            escapeValue: false,
            prefix: '%',
            suffix: '%'
        },
        react: {
            useSuspense: true
        },
        debug: process.env.NODE_ENV !== 'production'
    });

export default i18n;
