import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorCard } from '@weezevent/nacre';
import logoError from '../images/img_page_error.png';

export default function PageNotFound() {
    const { t } = useTranslation();

    return (
        <div className="wz-content">
            <ErrorCard image={logoError} title={t('partners.global.labelNotFound')} subTitle={t('partners.global.notFoundDescription')} />
        </div>
    );
}
