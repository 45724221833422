import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from './components/pageNotFound';
import ForbiddenPage from './components/forbiddenPage';
import PartnerRouter from './components/partnerRouter';
import TokenGuard from './components/tokenGuard';
import Template from './components/template/template';
import OutdatedBrowser from './common/outdated-browser';
import { isIE, isSafari, browserVersion } from 'react-device-detect';

const uuidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

function App() {
    //Display message outdated browser for IE and Safari 9
    if (isIE || (isSafari && browserVersion < 10)) {
        return <OutdatedBrowser />;
    }

    return (
        <Switch>
            <Route
                exact
                path="/forbidden"
                render={(basenameProps) => {
                    return (
                        <Template>
                            <ForbiddenPage />
                        </Template>
                    );
                }}
            />
            {/* partner-route */}
            <Route
                path={`/:partnerId(${uuidRegex})`}
                render={(basenameProps) => {
                    let { partnerId } = basenameProps.match.params;
                    return (
                        <TokenGuard history={basenameProps.history} partnerId={partnerId}>
                            <PartnerRouter partnerId={partnerId} history={basenameProps.history} match={basenameProps.match} />
                        </TokenGuard>
                    );
                }}
            />
            {/* partner-catch-all */}
            <Route
                render={(basenameProps) => {
                    return (
                        <Template>
                            <PageNotFound />
                        </Template>
                    );
                }}
            />
        </Switch>
    );
}

export default App;
