import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../context/appContext';
import PartnerUser from '../models/partnerUser';
import { fetcher } from '@weezevent/core';
import Loading from '../common/loading.js';

export default function TokenGuard({ history, partnerId, children }) {
    const [context, setContext] = useAppContext();

    const getUser = React.useCallback(
        (token) => {
            fetcher.setToken(token);
            return PartnerUser.get({ partnerId, me: true }).then((user) => {
                window.localStorage.setItem('hogwarts-token', token);
                setContext({ user });
            });
        },
        [partnerId, setContext]
    );

    const getTokenFromUrl = React.useCallback(() => {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        if (!urlParams?.has('token')) {
            return null;
        }
        let token = urlParams.get('token');
        return token;
    }, []);

    const cleanAndRedirect = React.useCallback(() => {
        window.localStorage.removeItem('hogwarts-token');
        history.push('/forbidden');
    }, [history]);

    React.useEffect(() => {
        let token = getTokenFromUrl();
        if (token) {
            getUser(token)
                .catch(() => {
                    token = window.localStorage.getItem('hogwarts-token');
                    if (!token) {
                        cleanAndRedirect();
                    }
                    return getUser(token);
                })
                .catch(() => {
                    cleanAndRedirect();
                });
            return;
        }
        token = window.localStorage.getItem('hogwarts-token');
        if (!token) {
            return cleanAndRedirect();
        }
        getUser(token).catch(() => {
            cleanAndRedirect();
        });
    }, [partnerId, history, setContext, getUser, getTokenFromUrl, cleanAndRedirect]);

    if (!context.user) {
        return <Loading />;
    }

    return <>{children}</>;
}

TokenGuard.propTypes = {
    history: PropTypes.any.isRequired,
    children: PropTypes.any,
    partnerId: PropTypes.string.isRequired
};
