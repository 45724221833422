import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function Loading() {
    const { t } = useTranslation();
    return (
        <div style={{ height: '50vh' }}>
            <Loader active>{t('partners.common.loading')}</Loader>
        </div>
    );
}
