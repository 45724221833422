import { BaseAPIModel } from '@weezevent/core';

export default class ProgressGuestsModel extends BaseAPIModel {
    // Allow us to call getAll directly
    static list(item) {
        return item;
    }

    static listUrl({ partnerId }) {
        if (!partnerId) {
            throw new Error(`[Model][ProgressGuestsModel][getAll] partnerId is mandatory`);
        }

        return `/ticket/partners/${partnerId}/guests/progress`;
    }
}
