import { BaseAPIModel } from '@weezevent/core';

export default class PartnerForm extends BaseAPIModel {
    static list(item) {
        return item;
    }

    static listUrl({ organizationId, partnerId }) {
        return `/ticket/organizations/${organizationId}/partners/${partnerId}/form`;
    }
}
