import { BaseAPIModel, ArgumentError } from '@weezevent/core';
/**
 * Chart.
 *
 * @param {Object} params - Object parameter
 * @constructor
 */
export default class AttendeeCreationRequest extends BaseAPIModel {
    fields() {
        return {
            uuid: { readOnly: true, primaryKey: true }
        };
    }

    static listUrl({ partnerId }) {
        if (!partnerId) {
            throw new ArgumentError('[Model][Attendee] partnerId is mandatory');
        }
        return `/ticket/partners/${partnerId}/attendees`;
    }
}
