import { BaseAPIModel, ArgumentError } from '@weezevent/core';
/**
 * Chart.
 *
 * @param {Object} params - Object parameter
 * @constructor
 */
export default class BatchStats extends BaseAPIModel {
    static getItemUrl(...args) {
        return this.listUrl(...args);
    }

    static listUrl({ partnerId }) {
        if (!partnerId) {
            throw new ArgumentError('[Model][BatchStats] partnerId is mandatory');
        }
        return `/ticket/partners/${partnerId}/stats-partner`;
    }
}
