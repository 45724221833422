import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { DropdownOrganization, FrameConsumer, AccountItem } from '@weezevent/nacre';
import { useAppContext } from '../../context/appContext';

function DropdownAccount({ history }) {
    const [context] = useAppContext();
    const { t } = useTranslation();

    const renderFullName = React.useCallback(() => {
        if (!context) {
            return '-';
        }

        return `${context?.user?.name}`;
    }, [context]);

    const logout = React.useCallback(() => {
        window.localStorage.removeItem('hogwarts-token');
        history.push('/logout');
    }, [history]);

    return (
        <FrameConsumer.Module.Account
            content={renderFullName()}
            items={
                <AccountItem
                    mail={context?.user?.email || ''}
                    secondary={{
                        onClick: logout,
                        label: t('partners.common.cta.logout')
                    }}
                    subtitle={t('partners.account.card_header')}
                    title={renderFullName()}
                />
            }
        />
    );
}

DropdownAccount.propTypes = {
    history: PropTypes.object
};

export default function Account({ partner, history }) {
    let dropdown = partner ? <DropdownOrganization title="" name={partner.name} /> : null;
    const { t } = useTranslation();

    return (
        <>
            <FrameConsumer.Module>{dropdown}</FrameConsumer.Module>
            <div className={'container-dropdown-account'}>
                <a target={'_blank'} rel="noopener noreferrer" href={t('partners.account.help_link')}>
                    <svg className={'icon-help'}></svg>
                </a>
                <DropdownAccount history={history} />
            </div>
        </>
    );
}

Account.propTypes = {
    partner: PropTypes.object,
    history: PropTypes.object
};
