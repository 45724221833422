import { BaseAPIModel } from '@weezevent/core';

export default class Partner extends BaseAPIModel {
    fields() {
        return {
            uuid: { primaryKey: true, readOnly: true },
            name: {}
        };
    }

    static listUrl() {
        return `/ticket/partners`;
    }
}
